@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: DM Sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 935px) {
  .container {
    max-width: 935px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.bottom-5 {
  bottom: 1.25rem;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.h-5 {
  height: 1.25rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 74 212 / var(--tw-bg-opacity));
}

.bg-white-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-base {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(34 74 212 / var(--tw-text-opacity));
}

.text-white-100 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-25 {
  opacity: .25;
}

.opacity-75 {
  opacity: .75;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html {
  scroll-behavior: smooth !important;
}

body {
  background-image: url("bg.b5f765dc.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

#verb, #location {
  color: #76d8b0;
  text-align: center;
  stroke-dashoffset: 0;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.text-stroke {
  stroke: #76d8b0;
  stroke-width: 4px;
  width: calc(100% - 20px);
  height: 20px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 10px;
}

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 154px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.text-stroke use {
  stroke-dasharray: 154;
  stroke-dashoffset: 154px;
  animation: .4s linear forwards drawStroke;
}

.slide-text {
  opacity: 0;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  transform: translateY(-20px);
}

input {
  color: #f4faf3;
  border: 2px solid #fff;
  border-radius: 8px;
  width: 100%;
  min-width: 250px;
  padding: 15px 10px;
  background-color: #f4faf31c !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #2586141a !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 30px #2586141a !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 30px #2586141a !important;
}

input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #2586141a !important;
}

input:hover {
  background-color: #f3f5fa1c;
}

input:focus {
  background-color: #f3f4fa1c !important;
}

input::placeholder {
  color: #fff;
}

.button {
  color: #fff;
  background: #001355;
  border-radius: 8px;
  min-width: 200px;
  padding: 15px 10px;
}

.button:hover {
  color: #fff;
  background: #000a2e;
}

.cookie-consent-container {
  text-align: center;
  background-color: #224ad4;
  width: 100%;
  padding: 10px;
  display: none;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 10px #0000001a;
}

.cookie-consent-content p {
  margin: 10px 0;
}

.cookie-consent-content a {
  color: #f3f3f3;
  text-decoration: underline;
}

#acceptCookieConsent {
  background-color: none;
  color: #fff;
  cursor: pointer;
  border: 1px solid #f4faf3;
  border-radius: 8px;
  padding: 10px 20px;
}

#acceptCookieConsent:hover {
  background-color: #1c3bac;
}

@media (width <= 768px) {
  body {
    background-image: url("bg2.d4fd4b6f.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
  }
}

@media (width >= 640px) {
  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-0 {
    gap: 0;
  }
}

@media (width >= 768px) {
  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }
}

@media (width >= 935px) {
  .xmd\:fixed {
    position: fixed;
  }

  .xmd\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xmd\:w-11\/12 {
    width: 91.6667%;
  }

  .xmd\:justify-center {
    justify-content: center;
  }

  .xmd\:overflow-hidden {
    overflow: hidden;
  }
}

@media (width >= 1024px) {
  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:-ml-8 {
    margin-left: -2rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:text-5xl {
    font-size: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
  }
}
/*# sourceMappingURL=index.f9dbadc0.css.map */
