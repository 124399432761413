@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth !important;
}

body {
  height: 100vh;
  background-image: url('../img/bg.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#verb,
#location {
  display: inline-block;
  color: #76D8B0;
  text-decoration: none;
  margin-right: 0px;
  padding: 0px;
  position: relative;
  text-align: center;
  stroke-dashoffset: 0;
}

.text-stroke {
  display: block;
  width: calc(100% - 20px);
  height: 20px;
  stroke: #76D8B0;
  position: absolute;
  left: 10px;
  bottom: -10px;
  stroke-width: 4;
}

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 154;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.text-stroke use {
  stroke-dasharray: 154px;
  stroke-dashoffset: 154px;
  animation: drawStroke 0.4s linear forwards;
}

.slide-text {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

input {
  background-color: #F4FAF31c !important;
  border-radius: 8px;
  padding: 15px 10px;
  min-width: 250px;
  width: 100%;
  color: #F4FAF3;
  border: #ffffff solid 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2586141a inset !important;
}


input:hover {
  background-color: #f3f5fa1c;
}

input:focus {
  background-color: #f3f4fa1c !important;
}

input::placeholder {
  color: #ffffff;
}

.button {
  background: #001355;
  color: #ffffff;
  border-radius: 8px;
  padding: 15px 10px;
  min-width: 200px;
}

.button:hover {
  background: #000A2E;
  color: #ffffff;
}

.cookie-consent-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #224AD4;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: none;
  /* Hidden by default */
}

.cookie-consent-content p {
  margin: 10px 0;
}

.cookie-consent-content a {
  color: #f3f3f3;
  text-decoration: underline;
}

#acceptCookieConsent {
  background-color: none;
  border: solid 1px #F4FAF3;
  border-radius: 8px;
  color: white; 
  padding: 10px 20px;
  cursor: pointer;
}

#acceptCookieConsent:hover {
  background-color: #1c3bac;
}

/* .verificationBadgeContainer
{
    
} */



@media (max-width: 768px) {
  body {
    min-height: 110vh;
    background-image: url('../img/bg2.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}